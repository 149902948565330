import type { EventOptions } from '@everfund/event-sidekick';

import { EventDetective } from '../eventDetective';
import { getPartialEvent } from '../getPartialEvent';
import { logEvent } from '../queue/logEvent';

export function actionAlias(client: InstanceType<typeof EventDetective>, action: actionEventAlias) {
  const { to, from, options } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      type: 'alias',
      userId: to,
      previousId: from,
      context: {
        ...partialEvent.context,
        traits: undefined,
      },
    },
  });

  return client.state;
}

export type actionEventAlias = {
  to: string;
  from?: string;
  options?: EventOptions;
};

export const defaultActionAlias: actionEventAlias = {
  to: 'u_123',
  from: 'u_124',
};
