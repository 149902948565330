import Cookies from 'js-cookie';

import type { AMP } from '@everfund/event-sidekick';

import { window } from '../../utils/globals';
import { EventDetective } from '../eventDetective';

export function getAMP(state: EventDetective['state']): AMP | undefined {
  if (window) {
    const ampId = Cookies.get('_ga');
    if (ampId != null) {
      state.amp = {
        id: ampId,
      };
    }
  }
  return state.amp?.id ? { id: state.amp?.id } : undefined;
}
