import type { EventOptions, EventTypes } from '@everfund/event-sidekick';

import { EventDetective } from '../eventDetective';
import { getPartialEvent } from '../getPartialEvent';
import { logEvent } from '../queue/logEvent';

export function actionScreen(client: InstanceType<typeof EventDetective>, action: actionEventScreen) {
  const { category, screen, props, options } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      type: 'screen',
      name: screen,
      properties: {
        ...partialEvent.context.page,
        ...partialEvent.properties,
        ...props,
        category,
      },
    },
  });
  return client.state;
}

export type actionEventScreen = {
  category?: string;
  screen?: string;
  props?: EventTypes['page'];
  options?: EventOptions;
};

export const defaultActionScreen: actionEventScreen = {
  category: 'one',
  screen: 'page one',
};
