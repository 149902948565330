export function searchParams(search: string): URLSearchParams | undefined {
  if (search === '' || search == null) {
    return undefined;
  }
  return new URLSearchParams(search);
}

export function deepEqual<Object1, Object2>(a: Object1, b: Object2): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}
