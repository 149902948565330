import type { EventOptions, UserTraits } from '@everfund/event-sidekick';

import { deepEqual } from '../../utils';
import { EventDetective } from '../eventDetective';
import { getPartialEvent } from '../getPartialEvent';
import { logEvent } from '../queue/logEvent';

export const USER_ID_KEY = 'user_id';
export const USER_TRAITS_KEY = 'user_traits';
export const ANONYMOUS_ID_KEY = 'anonymous_id';

export function actionIdentify(client: InstanceType<typeof EventDetective>, action: actionEventIdentify) {
  const { userId, traits = {}, options } = action;
  let newTraits = traits;
  if (client.state.userId == null || client.state.userId === userId) {
    newTraits = {
      ...client.state.userTraits,
      ...traits,
    };
  }

  if (client.state.userId === userId && deepEqual(client.state.userTraits, newTraits)) {
    return client.state;
  }

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      type: 'identify',
      context: {
        ...partialEvent.context,
        traits: undefined,
      },
      traits: newTraits,
    },
  });

  client.configStore.setValue(USER_ID_KEY, userId).setValue(USER_TRAITS_KEY, newTraits);

  client.setState({
    userId,
    userTraits: newTraits,
  });

  return client.state;
}

export type actionEventIdentify = {
  userId: string;
  traits?: UserTraits;
  options?: EventOptions;
};

export const defaultActionIdentity: actionEventIdentify = {
  userId: 'sD2fHd-7f62dd19-8ebf-439f-9d2c-99cc11929662',
  traits: {
    pricingPlan: 'enterprise',
  },
};
