import type { CommonPropType } from '@everfund/event-sidekick';

import { EventDetective } from '../eventDetective';

export const COMMON_PROPERTIES_KEY = 'common_props';

export function actionCommon(client: InstanceType<typeof EventDetective>, action: actionEventCommon) {
  const commonProperties = action;
  client.configStore.setValue(COMMON_PROPERTIES_KEY, commonProperties);

  client.setState({
    commonProperties,
  });

  return client.state;
}

export type actionEventCommon = Record<string, CommonPropType>;
