import { usePageLeaveEvent } from './hooks/usePageLeaveEvent';
import { usePageViews } from './hooks/usePageViews';
import { ProviderProps } from './provider';

export const PageTracker = (
  rest: Pick<ProviderProps, 'trackPageLeave' | 'trackPageViews' | 'trackOutboundLinks'>
): null => {
  usePageLeaveEvent({
    enabled: {
      outboundLinks: rest.trackOutboundLinks ?? false,
      pageLeave: rest.trackPageLeave ?? false,
    },
  });

  usePageViews({
    enabled: rest.trackPageViews ?? false,
  });

  return null;
};
