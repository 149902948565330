import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import NextLink from 'next/link';

import { locales } from './i18nConfig';

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({ locales }) as unknown as {
  Link: typeof NextLink;
  redirect: ReturnType<typeof createSharedPathnamesNavigation>['redirect'];
  usePathname: ReturnType<typeof createSharedPathnamesNavigation>['usePathname'];
  useRouter: ReturnType<typeof createSharedPathnamesNavigation>['useRouter'];
};
