'use client';

import { Suspense, createContext, useEffect, useMemo, useState, type ReactNode } from 'react';

import type { allConsentNames } from '@everfund/event-gdpr-types';
import type { Event } from '@everfund/event-sidekick';

import { useDetectiveClient } from './hooks/useDetectiveClient';
import { PageTracker } from './pageTracker';
import { DEFAULT_SIDEKICK_URL, EventDetective } from './reducer/eventDetective';
import type { ConsentConfigurationOrFlag } from './reducer/queue/checkConsent';

export type ProviderProps = {
  children?: ReactNode | ReactNode[];
  trackPageViews?: boolean;
  trackOutboundLinks?: boolean;
  trackPageLeave?: boolean;
  consent: ConsentConfigurationOrFlag;
  middleware?: (event: Event) => Event | undefined;
  uploadPath?: string;
  requiredGdprPurposes: allConsentNames[];
  debug?: boolean;
};

export const EventDetectiveContext = createContext<
  { client: EventDetective; loaded: boolean; state: EventDetective['state'] } | undefined
>(undefined);

export const EventDetectiveProvider = ({ children, ...rest }: ProviderProps) => {
  const { client, loaded } = useDetectiveClient({
    url: rest.uploadPath ?? DEFAULT_SIDEKICK_URL,
    consent: rest.consent,
    middleware: rest.middleware,
    debug: rest.debug,
  });

  const [state, setState] = useState<EventDetective['state']>(client?.state);

  useEffect(() => {
    if (loaded && client) {
      const unsubscribe = client.addListener((updatedState) => {
        setState(updatedState);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [client, loaded]);

  const memoizedValue = useMemo(
    () => ({
      client,
      loaded,
      state,
    }),
    [client, loaded, state]
  );

  return (
    <EventDetectiveContext.Provider value={memoizedValue}>
      <Suspense fallback={null}>
        <PageTracker
          {...{
            trackPageViews: rest.trackPageViews ?? false,
            trackPageLeave: rest.trackPageLeave ?? false,
            trackOutboundLinks: rest.trackOutboundLinks ?? false,
          }}
        />
      </Suspense>
      {children}
    </EventDetectiveContext.Provider>
  );
};
