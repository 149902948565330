export type consentType = {
  name: allConsentNames;
  description: string;
  gdprType: number;
  disabled?: boolean;
  defaultValue: boolean;
  display: boolean;
};

export type allConsentNames = 'necessary' | 'functionality' | 'measurement' | 'experience' | 'marketing';

export const consentTypes: consentType[] = [
  {
    name: 'necessary',
    description:
      'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us and, therefore, do not require you to consent',
    gdprType: 1,
    disabled: true,
    defaultValue: true,
    display: true,
  },
  {
    name: 'functionality',
    description:
      'These trackers enable basic interactions and functionalities that allow you to access selected features of our service and facilitate your communication with us.',
    gdprType: 2,
    defaultValue: false,
    display: false,
  },
  {
    name: 'measurement',
    description: 'These trackers help us to measure traffic and analyze your behavior to improve our service.',
    gdprType: 4,
    defaultValue: false,
    display: false,
  },
  {
    name: 'experience',
    description:
      'These trackers help us to improve the quality of your user experience and enable interactions with external content, networks and platforms',
    gdprType: 3,
    defaultValue: false,
    display: false,
  },
  {
    name: 'marketing',
    description:
      'These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance.',
    gdprType: 5,
    defaultValue: false,
    display: false,
  },
];
