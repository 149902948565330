import type { EventOptions, EventTypes } from '@everfund/event-sidekick';

import { EventDetective } from '../eventDetective';
import { getPartialEvent } from '../getPartialEvent';
import { logEvent } from '../queue/logEvent';

export function actionTrack(client: InstanceType<typeof EventDetective>, action: actionEventTrack) {
  const { name, properties, options } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      event: name as string,
      type: 'track',
      properties: {
        ...partialEvent.properties,
        ...properties,
      },
    },
  });

  return client.state;
}

export type actionEventTrack = {
  name: keyof EventTypes;
  properties: EventTypes[keyof EventTypes];
  options?: EventOptions;
};

export const defaultActionTrack: actionEventTrack = {
  name: 'cta_clicked',
  properties: {
    cta: 'button',
    type: 'speak-to-agent',
  },
};
