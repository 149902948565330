'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { type ReactNode, useEffect } from 'react';

import { useAnalytics } from './useAnalytics';

type UsePageViewsOptions = {
  enabled?: boolean;
};

export function usePageViews({ enabled = true }: UsePageViewsOptions): ReactNode {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const context = useAnalytics();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const url = pathname + searchParams.toString();
    context.page({ page: url.toString() });
  }, [pathname, searchParams, enabled, context]);

  return null;
}
