import type { Campaign } from '@everfund/event-sidekick';

import { searchParams, window } from '../../utils/globals';
import { EventDetective } from '../eventDetective';

export function parseCampaign(params: URLSearchParams): Campaign | undefined {
  const results: Campaign = {};

  for (const key of params.keys()) {
    if (key.substring(0, 4) === 'utm_') {
      let param = key.substring(4);
      if (param === 'campaign') {
        param = 'name';
      }
      results[param] = params.get(key) ?? undefined;
    }
  }
  if (Object.keys(results).length === 0) {
    return undefined;
  }
  return results;
}

export function getCampaign(state: EventDetective['state']): Campaign | undefined {
  if (window) {
    const { search } = location;
    const params = searchParams(search);
    if (params != null) {
      const campaign = parseCampaign(params);
      if (campaign != null) {
        state.campaign = campaign;
      }
    }
  }
  return state.campaign;
}
