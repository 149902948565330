import { navigator } from '../../utils/globals';
import { EventDetective } from '../eventDetective';

type ConsentConfiguration = {
  respectDnt?: boolean;
};

export type ConsentConfigurationOrFlag = ConsentConfiguration | boolean | undefined;

function parseConsentConfiguration(config: ConsentConfigurationOrFlag): ConsentConfiguration | undefined {
  if (config == null) {
    return undefined;
  }
  if (typeof config === 'boolean') {
    return config ? {} : undefined;
  }
  return config;
}

/**
 * This method helps to check if data can be collected or not.
 * returns true if it is okay to collect data.
 */
export function isDataCollectionAllowed(client: InstanceType<typeof EventDetective>): boolean {
  const options = parseConsentConfiguration(client?.options.consent);

  client.logDebug('isDataCollectionAllowed', {
    optionsNull: options == null,
    clientStateConsentDateConsented: client.state.consent?.dateConsented,
    navigatorDoNotTrack: navigator?.doNotTrack,
  });

  // return true as consent is not required
  if (options == null) {
    return true;
  }

  if (options.respectDnt === true && navigator) {
    if (navigator.doNotTrack !== null && [true, 1, '1', 'yes'].includes(navigator.doNotTrack)) {
      return false;
    }
  }

  return client.state.consent?.dateConsented != null;
}
