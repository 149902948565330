import type { EventOptions, EventTypes } from '@everfund/event-sidekick';

import { EventDetective } from '../eventDetective';
import { getPartialEvent } from '../getPartialEvent';
import { logEvent } from '../queue/logEvent';

export function actionPage(client: InstanceType<typeof EventDetective>, action: actionEventPage) {
  const { category, page, props, options } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      type: 'page',
      name: page,
      properties: {
        ...partialEvent.context.page,
        ...partialEvent.properties,
        ...props,
        category,
      },
    },
  });

  return client.state;
}

export type actionEventPage = {
  category?: string;
  page?: string;
  props?: EventTypes['page'];
  options?: EventOptions;
};

export const defaultActionPage: actionEventPage = {
  category: 'one',
  page: 'oldId',
};
