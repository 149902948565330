import type { EventOptions, GroupTraits } from '@everfund/event-sidekick';

import { deepEqual } from '../../utils';
import { EventDetective } from '../eventDetective';
import { getPartialEvent } from '../getPartialEvent';
import { logEvent } from '../queue/logEvent';

export const GROUP_ID_KEY = 'group_id';
export const GROUP_TRAITS_KEY = 'group_traits';

export function actionGroup(client: InstanceType<typeof EventDetective>, action: actionEventGroup) {
  const { groupId, traits = {}, options } = action;
  if (client.state.groupId === groupId && deepEqual(client.state.groupTraits, traits)) {
    return client.state;
  }

  const partialEvent = getPartialEvent({ client, options });
  logEvent({
    client,
    event: {
      ...partialEvent,
      type: 'group',
      groupId,
      context: {
        ...partialEvent.context,
        traits: undefined,
      },
      traits,
    },
  });

  client.configStore.setValue(GROUP_ID_KEY, groupId).setValue(GROUP_TRAITS_KEY, traits);

  client.setState({
    groupId,
    groupTraits: traits,
  });

  return client.state;
}

export type actionEventGroup = {
  groupId: string;
  traits?: GroupTraits;
  options?: EventOptions;
};

export const defaultActionGroup: actionEventGroup = {
  groupId: 'group-id',
  traits: {
    pricingPlan: 'enterprise',
  },
};
