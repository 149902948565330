import type { Referrer } from '@everfund/event-sidekick';

import { window, document } from '../../utils/globals';
import { searchParams } from '../../utils/globals';
import { EventDetective } from '../eventDetective';

const QUERY_IDS = {
  btid: 'dataxu',
  urid: 'millennial-media',
  ref: 'referrer',
};

export const REFERRER_KEY = 'referrer';

export function parseReferrer(params: URLSearchParams): Referrer | undefined {
  let referrer: Referrer | undefined;
  Object.keys(QUERY_IDS).find((key) => {
    if (params.has(key)) {
      referrer = {
        id: params.get(key) ?? undefined,
        type: QUERY_IDS[key as keyof typeof QUERY_IDS], // Add type assertion
        [key]: params.get(key),
      };
      return true;
    }
    return false;
  });
  return referrer;
}

export function parseDocumentReferrer(): Referrer | undefined {
  if (document && document.referrer) {
    try {
      const url = new URL(document.referrer);
      return {
        id: url.hostname,
        type: 'document',
        url: document.referrer,
      };
    } catch (error) {
      console.warn('Invalid document.referrer URL:', document.referrer);
      return undefined;
    }
  }
  return undefined;
}

export function getReferrer(client: InstanceType<typeof EventDetective>): Referrer | undefined {
  if (window) {
    const { search } = location;
    const params = searchParams(search);
    if (params != null) {
      const referrer = parseReferrer(params);
      if (referrer != null) {
        client.state.referrer = referrer;
        client.configStore.setValue(REFERRER_KEY, referrer);
        return referrer;
      }
    }

    const docReferrer = parseDocumentReferrer();
    if (docReferrer != null) {
      client.state.referrer = docReferrer;
      client.configStore.setValue(REFERRER_KEY, docReferrer);
      return docReferrer;
    }
  }

  return client.state.referrer;
}
